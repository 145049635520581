import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const brandPrimary = defineStyle({
  color: "blue.500"
});

export const linkTheme = defineStyleConfig({
  variants: { brandPrimary },
  defaultProps: {
    variant: "brandPrimary"
  }
});
