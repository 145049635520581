import * as Sentry from "@sentry/browser";
import { lazy, useCallback, useEffect, useReducer, Suspense } from "react";
import { db, analytics, auth } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { ChakraProvider } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { produce } from "immer";
import { Loading } from "./components/loading";
import { theme } from "./theme";
import { IntercomProvider } from "react-use-intercom";
import { BrowserRouter } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const configureSentry = data => {
  Sentry.configureScope(scope => {
    scope.setUser(data);
  });
};

const LoginScreen = lazy(() => import("./screens/login"));
const MainApp = lazy(() => import("./screens/mainApp"));

const initialState = {
  status: "initial",
  user: {
    uid: null
  },
  company: {}
};

const reducer = produce((state, action) => {
  switch (action.type) {
    case "company_changed": {
      state.company = action.payload;
      return state;
    }
    case "auth_state_changed": {
      const { user } = action.payload;
      state.user = user;
      state.status = "complete";
      state.company = {
        id: user.companyId
      };
      return state;
    }
    case "clear_state": {
      state.company = {};
      state.user = { uid: null };
      state.status = "un-auth";
      return state;
    }
    default: {
      return state;
    }
  }
});

function App() {
  const [appState, dispatch] = useReducer(reducer, initialState);

  const setupUserDetails = useCallback(user => {
    if (!user) {
      dispatch({
        type: "clear_state"
      });

      return;
    }

    onSnapshot(doc(db, "users", user.uid), snap => {
      if (!snap.exists()) {
        return;
      }

      const userRecord = snap.data();

      dispatch({
        type: "auth_state_changed",
        payload: {
          user: {
            uid: user.uid,
            verified: user.emailVerified,
            ...userRecord
          }
        }
      });

      configureSentry({
        companyId: userRecord.companyId,
        userId: user.uid,
        userEmail: userRecord.email
      });
    });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      configureSentry({ email: user && user.email, id: user && user.uid });
      setupUserDetails(user);
      logEvent(analytics, user ? "logged_in" : "logged_out");
    });
    return () => {
      unsubscribe();
    };
  }, [setupUserDetails]);

  return (
    <>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <ChakraProvider theme={theme} portalZIndex={40}>
          <Loading loading={appState.status === "initial"}>
            <Elements stripe={stripePromise}>
              <Base appState={appState} dispatch={dispatch} />
            </Elements>
          </Loading>
        </ChakraProvider>
      </BrowserRouter>
    </>
  );
}

function Base({ appState, dispatch }) {
  const { uid } = appState.user;
  const successLogin = !!uid;

  const changeCompany = company => {
    dispatch({
      type: "company_changed",
      payload: company
    });
  };

  const app = successLogin ? (
    <MainApp state={appState} changeCompany={changeCompany} />
  ) : (
    <LoginScreen />
  );

  return (
    <IntercomProvider appId="oovtc8q8">
      {/* @ts-ignore*/}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="*" element={app} />
        </Routes>
      </Suspense>
    </IntercomProvider>
  );
}

export default App;
