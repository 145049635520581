import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import defaultTheme from "@chakra-ui/theme";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

/* @ts-ignore */
const tabsVariant = defaultTheme.components.Tabs.variants.line;

// define custom variants
const colorfulVariant = definePartsStyle(props => {
  const { colorScheme: c } = props; // add colorScheme as a prop

  return {
    /* @ts-ignore */
    ...tabsVariant(props),
    tab: {
      ...tabsVariant(props).tab,
      color: `${c}.500`,
      paddingBottom: 2
    }
  };
});

const variants = {
  colorful: colorfulVariant
};

// define which sizes, variants, and color schemes are applied by default
const defaultProps = {
  size: "sm",
  variant: "colorful",
  colorScheme: "blue"
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({
  variants,
  /* @ts-ignore */
  defaultProps
});
