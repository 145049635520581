import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";
import { tabsTheme } from "./tabs";
import { linkTheme } from "./link";
import { buttonTheme } from "./button";
import { tableTheme } from "./table";

export const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false
  },
  components: {
    Table: tableTheme,
    Link: linkTheme,
    Tabs: tabsTheme,
    Button: buttonTheme,
    Popover: {
      variants: {
        responsive: {
          content: { width: "unset" },
          popper: {
            maxWidth: "unset",
            width: "unset"
          }
        }
      }
    },
    Input: {
      variants: {
        outline: {
          addon: {
            paddingStart: "2",
            paddingEnd: "2",
            fontSize: "sm"
          },
          field: {
            background: "white",
            fontSize: "sm"
          }
        }
      }
    },
    Tooltip: {
      baseStyle: {
        whiteSpace: "nowrap",
        fontSize: "xs",
        borderRadius: "lg",
        backgroundColor: "gray.600"
      }
    }
  },
  styles: {
    global: {
      html: {
        WebkitFontSmoothing: "antialiased", // Antialiasing.
        MozOsxFontSmoothing: "grayscale", // Antialiasing.
        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: "border-box"
      },
      body: {
        fontSize: 14,
        color: "gray.600",
        _dark: {
          color: "white"
        }
      },
      "*, *::before, *::after": {
        boxSizing: "inherit"
      },
      ".firebase-emulator-warning": { display: "none" },
      ".kill_scrollbars": {
        "::-webkit-scrollbar": {
          display: "none"
        },
        "-ms-overflow-style": "none"
      },

      // MUI date range picker has a backdrop that keeps overflowing its container
      "[class*='materialui-daterange-picker-makeStyles-dateRangeBackdrop']": {
        width: "auto !important",
        height: "auto !important"
      }
    }
  },
  fonts: {
    brand: "'Muli', sans-serif",
    body: "Muli, sans-serif",
    heading: "Muli, sans-serif"
  },
  letterSpacings: {
    normal: "0.03em"
  },
  colors: {
    current: chakraTheme.colors.gray,
    error: chakraTheme.colors.red["500"],
    brand: {
      body: chakraTheme.colors.gray,
      buttonPrimary: chakraTheme.colors.blue,
      buttonSecondary: chakraTheme.colors.gray
    }
  }
});
