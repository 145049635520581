import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import defaultTheme from "@chakra-ui/theme";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

/* @ts-ignore */
const simpleTableVariant = defaultTheme.components.Table.variants.simple;
const stripedTableVariant = defaultTheme.components.Table.variants.striped;

// define custom variants
const basicVariant = definePartsStyle(props => {
  const variantDefaults = simpleTableVariant(props);

  return {
    /* @ts-ignore */
    ...variantDefaults,
    table: {
      fontSize: "xs"
    },
    tbody: {
      background: "white",
      th: {
        background: "inherit"
      }
    },
    th: {
      ...variantDefaults.th,
      textTransform: "none",
      background: "gray.200",
      color: "inherit"
    },
    td: {
      ...variantDefaults.td,
      paddingTop: 4,
      paddingBottom: 4
    }
  };
});

const stripedVariant = definePartsStyle(props => {
  return {
    ...stripedTableVariant(props),
    table: {
      background: "white"
    },
    tr: {
      _even: {
        td: {
          background: "gray.100"
        }
      }
    }
  };
});

const trimmedVariant = definePartsStyle(props => {
  return {
    ...simpleTableVariant(props),
    table: {
      background: "transparent",
      fontSize: "sm",
      color: "black",
      borderBottom: "1px solid",
      borderBottomColor: "gray.200"
    },
    tbody: {
      th: {
        borderRightColor: "gray.300",
        borderBottomColor: "gray.300"
      }
    },
    thead: {
      th: {
        borderBottomColor: "gray.300",
        borderRightColor: "gray.300",
        borderTopColor: "gray.300"
      }
    },
    th: {
      background: "white",
      fontWeight: "bold",
      color: "gray.800",
      textTransform: "none",
      fontSize: "xs",
      border: "1px solid",
      borderColor: "gray.200",
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 2.5,
      paddingBottom: 2.5
    },
    td: {
      "&[data-is-numeric=true]": {
        textAlign: "right"
      },
      background: "white",
      border: "1px solid",
      borderColor: "gray.200",
      color: "gray.600",
      fontSize: "xs",
      paddingLeft: 2,
      paddingRight: 2,
      paddingTop: 2.5,
      paddingBottom: 2.5
    }
  };
});

const variants = {
  basic: basicVariant,
  striped_alt: stripedVariant,
  trimmed: trimmedVariant
};

// define which sizes, variants, and color schemes are applied by default
const defaultProps = {
  variant: "basic"
};

// export the component theme
export const tableTheme = defineMultiStyleConfig({
  variants,
  /* @ts-ignore */
  defaultProps
});
