import { useState, useEffect } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { Logo } from "./logo";

const Loader = ({ children }) => (
  <Flex
    height="100vh"
    width="100vw"
    justifyContent="center"
    alignItems="center"
    direction="column"
    background="gray.800"
  >
    <Box textAlign="center">
      <Logo width="100%" />
      <Text>{children}</Text>
    </Box>
  </Flex>
);

export const Loading = ({
  text = "Loading",
  loading = true,
  children,
  minLoadingTime = 1000
}) => {
  const [canShow, setCanShow] = useState(false);
  const show = !loading && canShow;

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanShow(true);
    }, minLoadingTime);

    return () => clearTimeout(timer);
  }, [minLoadingTime]);

  return show ? children : <Loader>{text}...</Loader>;
};
