import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./sentry";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";
import { ColorModeScript } from "@chakra-ui/react";
import { createStandaloneToast } from "@chakra-ui/toast";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./theme";

const { ToastContainer } = createStandaloneToast({ theme });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
    <ToastContainer />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
