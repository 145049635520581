import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

import defaultTheme from "@chakra-ui/theme";
const solidVariant = defaultTheme.components.Button.variants.solid;
const ghostVariant = defaultTheme.components.Button.variants.ghost;
const outlineVariant = defaultTheme.components.Button.variants.outline;

const primaryVariant = defineStyle(props => {
  const { colorScheme: c, ...rest } = props;
  return {
    ...solidVariant({ colorScheme: c || "blue", ...rest })
  };
});

const secondaryVariant = defineStyle(props => ({
  ...ghostVariant(props)
}));

const link = defineStyle({
  color: "blue.500",
  fontSize: "inherit"
});

const saveVariant = defineStyle(props => ({
  ...solidVariant({ ...props, colorScheme: "green" })
}));

const destructiveVariant = defineStyle(props => ({
  ...solidVariant({ ...props, colorScheme: "red" })
}));

const outlineVariantAdjusted = defineStyle(props => ({
  ...outlineVariant(props),
  borderColor: "gray.300",
  background: "white"
}));

const destructiveGhostVariant = defineStyle(props => ({
  ...ghostVariant({ ...props, colorScheme: "red" })
}));

export const buttonTheme = defineStyleConfig({
  variants: {
    link,
    save: saveVariant,
    primary: primaryVariant,
    secondary: secondaryVariant,
    destructive: destructiveVariant,
    destructive_ghost: destructiveGhostVariant,
    outline: outlineVariantAdjusted
  },
  defaultProps: { variant: "primary", colorScheme: "" }
});
